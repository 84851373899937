import React from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from 'gatsby'
import { IconClose, IconFacebook, IconTwitter, IconContact } from '../components/elements/icon'
import { mq, getGridSpans } from '@stylesheets/settings'

import Layout from "../components/layout"
import SEO from "../components/seo"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${getGridSpans('width', { s:5, m: [4, 'full'], l: 6 })};
  margin: 0 auto;
  padding: 60px 0px;

  ${mq('m')} {
    padding: 50px 0px;
  }

`;

const CloseButton = styled(Link)`
  position: absolute; 
  right: 20px; 
  top: 20px;
  width: 40px;
  height: 40px;
  alignSelf:flex-end;
`;

const ShareBar = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
  height: 50px;
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
`;

const ShareButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; 
  height: 40px;
  img {
    user-select: none;
    user-drag: none;
  }
`;

const DetailPage = ({ pageContext }) => {
  
  const data = useStaticQuery(graphql`
    {
      detailImage: allFile(filter: {relativeDirectory: {eq: "gallery"}}) {
        nodes {
          publicURL
          name
        }
      }
      shareImage: allFile(filter: {relativeDirectory: {eq: "share"}}) {
        nodes {
          publicURL
          name
        }
      }
      site {
        siteMetadata {
          title
          description
          author
          imageWidth
          imageHeight
        }
      }
    }
  `)

  const match = (type, dimension) => {
    return data[type].nodes.find(({ name }) => name === `${pageContext.image}_${dimension}`) || { publicURL: '' };
  };

  const imageURL = (type, dimension) => {
    return match(type, dimension)['publicURL']
  };

  const shareImageURL = (shareImage) => {
    const image = data['shareImage'].nodes.find(({ name }) => name === shareImage) || { publicURL: '' };
    return image['publicURL'];
  }
  
  return (
    <Layout>
      <SEO 
        title={pageContext.name}
        meta={[
          {
            property: 'og:image:width',
            content: '1190',
          },
          {
            property: 'og:image:height',
            content: '630',
          },
          { 
            property: 'og:image', 
            content: `http://unhate-women.com${shareImageURL(pageContext.shareImage)}`
          },
          { 
            property: 'og:image:secure_url', 
            content: `https://unhate-women.com${shareImageURL(pageContext.shareImage)}`
          }
        ]} 
      />
      <Wrapper>
        <CloseButton fill={'black'} to="/"><IconClose fill={'black'} width={'25px'} height={'25px'}></IconClose></CloseButton>
        <img style={{width: '100%', maxWidth: '800px', margin: '0 auto'}}src={imageURL('detailImage', 'medium')}/>
        <ShareBar>
          <span style={{fontSize: '18px'}}>Motiv teilen: </span>
          <ShareButton target="_blank" rel="noreferrer noopener" href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent('https://unhate-women.com/de/'+pageContext.motivePath)}`}>
            <IconFacebook fill={'black'} width={'25px'} height={'25px'} />
          </ShareButton>
          <ShareButton target="_blank" rel="noreferrer noopener" href={`https://twitter.com/intent/tweet?text=${data.site.siteMetadata.description}&url=${encodeURIComponent('https://unhate-women.com/de/'+pageContext.motivePath)}`}>
            <IconTwitter fill={'black'} width={'25px'} height={'25px'} />
          </ShareButton>
          <ShareButton href={`mailto:?subject=#UNHATEWOMEN&body=${data.site.siteMetadata.description}%0D%0A%0D%0Ahttps://unhate-women.com${pageContext.motivePath}`}>
            <IconContact fill={'black'} width={'25px'} height={'25px'} />
          </ShareButton>
        </ShareBar>
      </Wrapper>
    </Layout>
  );
};

export default DetailPage
